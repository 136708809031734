<template>
  <!-- 老师管理 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="会员名称">
          <el-input style="width:250px" v-model="searchData.title" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button style="margin-bottom:20px;" type="primary" icon="el-icon-plus" size="small" @click="goAdd"> 添加</el-button>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
  </div>
</template>

<script>
import { getList, changeStatus, removeMember } from './api'

import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '会员标题',
    prop: 'title',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['title']}</p>
    }
  },
  {
    label: '背景图',
    minWidth: '100',
    prop: 'bg_image',
    customRender(h, row) {
      return <el-image style="width: 60px; height: 60px;margin-top:5px;" src={row['bg_image']} fit="fit"></el-image>
    }
  },
  {
    label: '排序值',
    minWidth: '100',
    prop: 'sort',
    customRender(h, row) {
      return <p>{row['sort']}</p>
    }
  },
  {
    label: '描述',
    minWidth: '200',
    prop: 'describe',
    customRender(h, row) {
      return <p class="one-line">{row['describe']}</p>
    }
  },
  {
    label: '创建时间',
    minWidth: '160',
    prop: 'created_at',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['created_at']}>
          {row['created_at']}
        </p>
      )
    }
  },
  {
    label: '操作',
    fixed: 'right',
    minWidth: '200',
    customRender(h, row) {
      return (
        <div>
          <el-link type="primary" style="margin-right:10px;" onClick={() => this.goEdit(row['id'])}>
            编辑
          </el-link>
          <el-link type="danger" onClick={() => this.goRemove(row['id'])}>
            删除
          </el-link>
        </div>
      )
    }
  }
]

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      dialogVisible: false,
      isShow: false,
      list: [],
      page_size: 10,
      searchData: {
        title: '',
        mobile: ''
        // roleId: ''
      },
      total: 0,
      currentPage: 1,
      columns,
      roleList: []
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        title: this.searchData.title
      }
      const res = await getList(params)
      this.list = res.data || []
      this.total = res.total || 0
    },

    goAdd() {
      this.$router.push({ name: 'memberLevelAdd' })
    },
    goEdit(id) {
      this.$router.push({ name: 'memberLevelEdit', params: { id: id || undefined } })
    },
    async goRemove(id) {
      this.$confirm('此操作将永久删除该等级, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          removeMember({ id }).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>

import { http } from '@/http/axios.js'

// 列表
export function getList(params) {
  return http({
    url: `/company/member/list`,
    method: 'get',
    params
  })
}

export function removeMember(params) {
  return http({
    url: `/company/member/delete`,
    method: 'post',
    params: params
  })
}
export function addMember(params) {
  return http({
    url: `/company/member/add`,
    method: 'post',
    params: params
  })
}

export function editMember(params) {
  return http({
    url: `/company/member/edit`,
    method: 'post',
    params: params
  })
}

export function detail(id) {
  return http({
    url: `/company/member/detail`,
    method: 'get',
    params: { id: id }
  })
}
